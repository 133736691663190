@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700;900&family=Rammetto+One&family=Lalezar&family=Didact+Gothic&family=Marcellus+SC&family=Poppins:wght@300;400&family=Rowdies:wght@300;400;700&family=Workbench&display=swap');
@font-face {
    font-family: 'comicy';
    src: local('comicy'), url('Comicy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
.container{
    background-color: #7c4329;
}

.bg{
    position: absolute;
    width: 100%;
    opacity: 5%;
}
.mascot-container{
    overflow: hidden;

    font-family: 'lalezar';
    font-weight: bold;
    /* display: flex; */
    display: grid;
    grid-template-columns: 30vw 40vw 10vw;
    height: 75vh;
    align-items: center;}


.brown-typo{
    /* width: min-content; */
    text-align: center;
    /* transform: translateX(13vw); */
}
.brown-typo-text1{
    height: 20vh;
    animation: stomp 1.5s ease-in 1;
}
@keyframes stomp{
    0%   {transform: translateY(-30vh)}
    40%  {transform: translateY(3vh)}
    75%{transform: translateY(2vh)}
    90%{transform: translateY(3vh)}
    100% {transform: translateY(2vh)}
}

.jaggery-text h1{
    margin: 0;
    line-height: 8vh;
    /* letter-spacing: 0.4vw; */
    /* color: #442618; */
    color: bisque;
    animation: stomp2 1.5s 0.6s 1;
}
@keyframes stomp2{
    0%   {transform: translateY(2vh)}
    40%  {transform: translateY(3vh)}
    75%{transform: translateY(2vh)}
    90%{transform: translateY(3vh)}
    100% {transform: translateY(2vh)}
}
.j1{
    font-size: 10vh;
}
.j2{
    font-size: 12vh;
}
.j3{
    font-size: 8vh;
}
.j4{
    font-size: 10vh;
}
.mascot2{
    z-index: 1;
    height: 70vh;
    top: 2.5vh;
    position: relative;
}

.bg1{
    position:relative;
    width: 100%;
    z-index: 1;
    top: 0;
}

.pkg1{
    /* transform: translateX(18vw); */
    animation: shake 2s ease-in 3;
}
@keyframes shake {
    0%, 100% {transform: translateX(-2vw) rotate(-1deg);}
    50% {transform: translateX(0vw) rotate(-3deg);}
    80% {transform: translateX(0vw) rotate(3deg);}
}
.content{
    text-align: center;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.4%;
    height: 40vh;
    margin: 0 3vw;

}

.insta-logo{
    height:40%;
}

.mascot{
    height: 34vh;
    width: fit-content;
    /* background-color: black; */
}
.block1, .block2, .block3{
    border-radius: 30px;
    border: 1vh solid #9c522f;
    border-top: none;
    margin: 0 0.5vw;
    z-index: 2;
    align-content: center;
    color: #442618;
    height: 25vh;
    font-size:x-large;
    /* background-color: #d0ab90; */
    background-color: bisque;
    font-family: 'rammetto one';
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
}
.block1 h1, .block2 h1, .block3 h1{
    margin: 0;
    animation: big 2s 3s ease-in-out 3;
}
@keyframes big {
    0%, 100% {transform: scale(1);transform: translateY(-2vh);}
    50% {transform: scale(1.5);transform: translateY(2vh)}
}

.block1 h2, .block2 h2, .block3 h2{
        margin: 0;}
    
/* Hover effect for the blocks */
.block1:hover, .block2:hover, .block3:hover{
    box-shadow: inset -7px -7px 10px rgba(0,0,0,.5), inset 7px 7px 10px rgba(255,255,255,.5);
    transform: translateY(-7px);
    transition: all .5s ease-in-out;
}

/* .block1:active, .block2:active, .block3:active{
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    transform: translateY(0) rotateZ(180deg);
    transition: all .5s ease-in-out;
} */

footer{
    align-items: center;
    text-align: center;
    padding: 2vh;
    display: grid;
    grid-template-columns: repeat(3,33.3%);
}
.sk1,.sk2{
    height: 20vh;
}
footer h3, footer h2{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    position: relative;
    margin: 0;
    border-radius: 15px;
    padding: 1vh;
    color: white;
}
/* footer h3{
    font-family: 'lalezar';
} */

/* Media Queries for Mobile */
@media only screen and (max-width: 768px) {
    .mascot-container {
        grid-template-columns: 10vw 70vw 10vw;
        height: 60vh;
        padding-top: 5vh;
        padding-bottom: 10vh;
    }

    .bg{
        display: none;
    }
    .mascot2{
        top: 30vh;
        left: 35vw;
        height: 20vh;
    }
    
    .brown-typo-text1{
        height: 12vh;

    }
    .jaggery-text h1{
        line-height: 6vh;

    }
   .j1{
    font-size:7vh;
   }
.j2{
    font-size: 8vh;
}
.j3{
    font-size: 6vh;
}
.j4{
    font-size: 8vh;
}
.pkg1{
    opacity: 0;
}
    .brown-typo-text2 {
        font-size: medium;
    }

    .block1, .block2, .block3 {
        height: 20vh;
        font-size: medium;
    }
   .content{
    height: auto;
   }
    .sk1, .sk2{
    height: 10vh;
    }
    footer h2{
        font-size: medium;
    }
}