nav{
    position: absolute;
    margin: 0; 
    padding-left: 2vw;
    padding: 4vh 0;
    text-align: right;
    z-index: 2;
    right: 3vw;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
nav ul li{
    padding-left: 2.5vw;

}
nav ul{
    align-items: center;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

nav li a{
    text-decoration: none;
    color: white;
    font-weight: bold;
    /* letter-spacing: 1.5px; */
    /* padding-right: 2vw; */
    cursor: pointer;
    font-size:large;
    
}
.insta{
    height: 6vh;
}
.bucklu{
    color: yellow;
    outline-color: black;
    text-align: center;
}
    